<template>
  <div class="noSel" style="padding: 40px 0">
    <div class="record_header flex a_c">
      <div @click="choseRec()" :class="{ on: recordChose == 'recommend' }">推荐搭配</div>
      <!-- <div @click="choseBuy()" :class="{ on: recordChose == 'buy' }">购买记录</div> -->
    </div>
    <div class="record_shopList flex j_s">
      <div class="box" v-for="(item, i) in showList" :key="i">
        <img @click="toDetails(i)" :src="item.img_path" alt />
        <div class="text">{{ item.title }}</div>
        <div class="text">{{ item.sku_title }}</div>
        <div class="addCart" @click="addAdd(i)">加入购物车</div>
      </div>
      <div v-show="boughtList.length == 0 && recordChose == 'buy'" class="tips">您没有购买过任何产品！</div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import storage from "@/utils/storage";
export default {
  name: "",
  props: {},
  data() {
    return {
      recordChose: "recommend",
      shopComList: [],
      boughtList: [
        {
          img: "https://static.5t1800.com/s2/2021/06/11/60c2ba1412257.png",
          name: "保湿面膜立减80元保湿面膜立减80元",
          nameEng: "baoshimianmo",
          type: "保湿面膜",
          volume: "60ml",
          price: 1800,
          count: 1
        }
      ],
      showList: []
    };
  },
  created() {
    this.getShopRec();
  },
  components: {},
  methods: {
    //加入购物车
    addAdd(i) {
      var value = this.showList[i];
      console.log(value);
      var shop = {},
        goods_params = [];
      goods_params.push({
        id: value.id,
        number: 1,
        skuid: value.sku_id
      });
      shop.img_path = value.img_path;
      shop.skuTitle = value.sku_title;
      shop.title = value.title;
      shop.price = value.price;
      shop.number = 1;
      shop.skuid = value.sku_id;
      shop.goods_params = goods_params;
      console.log(shop);
      this.WYfun.getShopCartSto(shop);
      this.$message({
        message: "添加成功",
        type: "success",
        duration: 1500,
        onClose: () => {
          window.location.reload();
        }
      });
    },
    //跳转详情
    toDetails(i) {
      var saveInfo = this.showList[i];
      storage.set("productDetails", saveInfo, true);
      this.routerTo("productDetails");
    },
    //二级路由跳转
    routerTo(value) {
      this.$router.push({ name: value });
    },
    //选择推荐
    choseRec() {
      this.recordChose = "recommend";
      this.showList = this.shopComList;
    },
    //选择购买历史
    choseBuy() {
      this.recordChose = "buy";
      this.showList = this.boughtList;
    },
    //获取商品推荐列表
    getShopRec() {
      var params = {
        isrec: 1,
        limit: 3
      };
      api
        .$getShopList(params)
        .then(res => {
          console.log(res.data);
          if (res.data.data.list.length != 0) {
            this.shopComList = res.data.data.list;
            this.showList = this.shopComList;
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>
.record_header {
  height: 27px;
  border-left: 1px solid #333333;
  border-bottom: 1px solid #333333;
  margin-bottom: 20px;
}
.record_header > div {
  width: 78px;
  height: 27px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-align: center;
  line-height: 27px;
  cursor: pointer;
  border-right: 1px solid #333333;
  border-top: 1px solid #333333;
  box-sizing: border-box;
}
.record_header .on {
  background: #cdcdcd;
}
.record_shopList {
  height: 500px;
}
.record_shopList .box {
  width: 292px;
}
.record_shopList .box img {
  width: 292px;
  height: 292px;
  display: block;
  margin-bottom: 15px;
}

.record_shopList .box .text {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 23px;
  text-align: center;
}

.record_shopList .box .addCart {
  width: 92px;
  height: 27px;
  background: #000000;
  line-height: 27px;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin: 10px auto;
  cursor: pointer;
}
.tips {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.box img {
  cursor: pointer;
}
</style>
