<template>
  <div class="cart">
    <div class="header">您的购物车</div>
    <div class="flex j_s">
      <div class="left">
          <CartList></CartList>
          <Record></Record>
      </div>
      <div class="right">
        <div class="difficulty">
          <div class="difficulty_header">支付中遇到困难？</div>
          <div class="online">在线客服</div>
          <div class="onlineText">
            我们的在线客服顾问将为您及时<span
              style="color: #436de5; cursor: pointer"
              >解答疑问》</span
            >
          </div>
          <div class="online">服务热线</div>
          <div style="padding: 5px 0">400-604-2566</div>
          <div>服务时间</div>
          <div>上午9:00-晚上22:00</div>
        </div>
        <div class="return">
          <div class="return_header"><span>7</span>天</div>
          <div>7天无理由退货</div>
          <div class="return_b">安心便携的退换货服务详细内容请参考“退换货政策”</div>
          <div style="padding-bottom:10px;">送货服务</div>
          <div class="flex a_c j_s" style="padding:0 20px;">
              <img style="width:96px;height:35px;" src="https://static.5t1800.com/s2/2021/06/17/60cac575586a6.png" alt="">
              <img style="width:79px;height:37px;" src="https://static.5t1800.com/s2/2021/06/17/60cac5ac92b96.png" alt="">
          </div>
          <div class="return_b" style="padding:10px 0;">我们仅选用最卓越的、信赖的物流合作伙伴</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CartList from '@/components/shopCart/cartList'
import Record from '@/components/shopCart/record'
export default {
  data() {
    return {};
  },
  created() {
    this.fixType();
  },
  computed: {},
  components: {
      CartList,
      Record
  },
  methods: {
    //固定type栏状态
    fixType() {
      this.$store.commit("saveTypeIndex", 9);
    },
  },
};
</script>

<style scoped>
.cart {
  width: 1200px;
  margin: 0 auto;
  padding-top: 37px;
}
.cart .header {
  height: 66px;
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 66px;
}
.cart .left {
  width: 897px;
}
.cart .right {
  width: 274px;
}
.right .difficulty {
  height: 266px;
  border: 1px solid #999999;
  margin-bottom: 19px;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.right .difficulty_header {
  height: 38px;
  border-bottom: 1px solid #999999;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-align: center;
  line-height: 38px;
}
.online {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  padding: 10px 0;
}
.onlineText {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  width: 140px;
  margin: 0 auto;
  padding: 10px 0;
}
.right .return {
  height: 266px;
  border: 1px solid #999999;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-align: center;
}
.return_header span {
    font-size: 36px;
}
.return_b {
    width:173px;
    margin: 0 auto;
    text-align: left;
    padding:20px 0;
}
</style>

