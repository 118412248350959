<template>
  <div>
    <div class="listBox noSel">
      <div class="flex a_c" v-for="(item, i) in cartList" :key="i">
        <div class="listChoseIcon" :class="{ on: item.isChose }" @click="choseCartShop(i)">
          <img src="https://static.5t1800.com/s2/2021/06/17/60cb0089277e8.png" alt />
        </div>
        <img width="200px" height="200px" :src="item.img_path" alt />
        <div class="flex" style="height: 160px">
          <div class="info">
            <div>{{ item.title }}</div>
            <div>容量{{ item.skuTitle }}</div>
          </div>
          <div class="priceOne">￥{{ item.price | keepTwoNum }}</div>
          <div class="countNum flex">
            <div class="countNum_box flex j_s a_c">
              <div @click="reduceCount(i)" class="operBox reduce">
                <img src="https://static.5t1800.com/s2/2021/06/17/60caeaf7cdaa0.png" alt />
              </div>
              <div class="num">{{ item.number }}</div>
              <div @click="addCount(i)" class="operBox add">
                <img src="https://static.5t1800.com/s2/2021/06/17/60caeaf4e670f.png" alt />
              </div>
            </div>
          </div>
          <div class="priceAll">￥{{ item.price * item.number | keepTwoNum }}</div>
          <div @click="removerShopCart(i)" class="moveBt">移除</div>
        </div>
      </div>
      <div v-show="cartList.length == 0" style="padding-left:20px">暂无商品，请选择添加！</div>
    </div>
    <div class="listAll flex j_s a_c">
      <div class="flex a_c">
        <div class="listChoseIcon" :class="{ on: isShopChoseAll }" @click="choseAllCartShop">
          <img src="https://static.5t1800.com/s2/2021/06/17/60cb0089277e8.png" alt />
        </div>
        <div @click="removeAll" class="listAll_leftText1">删除选中物品</div>
        <div class="listAll_leftText2">删除失效物品</div>
      </div>
      <div class="flex">
        <div class="shopChosed">
          已选商品
          <span style="color: #ff2742">{{ cartChosedAllLeng }}</span>件
        </div>
        <div
          style="
            width: 1px;
            height: 30px;
            background: #999999;
            margin: 0 20px;
            margin-top: 4px;
          "
        ></div>
        <div>
          <div class="shopPriceAll">
            总额（不含运费）:
            <span
              style="color: #ff2742; font-size: 14px"
            >￥{{ cartChosedAllPrice | keepTwoNum }}</span>
          </div>
          <div class="ship">商品包邮</div>
        </div>
        <div @click="toSettle" class="but settlement">去结算</div>
        <div @click="toBuy" class="but shopping">继续购物</div>
      </div>
    </div>
  </div>
</template>

<script>
import storage from "@/utils/storage";
export default {
  name: "",
  props: {},
  data() {
    return {
      cartList: [
        {
          img: "https://static.5t1800.com/s2/2021/06/11/60c2ba1412257.png",
          name: "保湿面膜立减80元保湿面膜立减80元",
          nameEng: "baoshimianmo",
          type: "保湿面膜",
          volume: "60ml",
          price: 1800,
          count: 1
        },
        {
          img: "https://static.5t1800.com/s2/2021/06/11/60c2ba1412257.png",
          name: "保湿面膜立减80元",
          nameEng: "baoshimianmo",
          type: "保湿面膜",
          volume: "60ml",
          price: 1800,
          count: 1
        },
        {
          img: "https://static.5t1800.com/s2/2021/06/11/60c2ba1412257.png",
          name: "保湿面膜保湿面膜立减80元",
          nameEng: "baoshimianmo",
          type: "保湿面膜",
          volume: "60ml",
          price: 1800,
          count: 1
        }
      ],
      cartChosedAllPrice: 0,
      cartChosedAllLeng: 0,
      isShopChoseAll: false
    };
  },
  created() {
    this.getShopCartList();
  },
  computed: {},
  methods: {
    //减少单个商品数量
    reduceCount(i) {
      if (--this.cartList[i].number < 1) {
        this.cartList[i].number = 1;
      }
      this.cartListChange();
    },
    //增加单个商品数量
    addCount(i) {
      this.cartList[i].number++;
      this.cartListChange();
    },
    //选中商品
    choseCartShop(i) {
      this.cartList[i].isChose = !this.cartList[i].isChose;
      this.isChoseAll_Fut();
      this.cartListChange();
    },
    //全选商品
    choseAllCartShop() {
      this.isShopChoseAll = !this.isShopChoseAll;
      this.cartList.forEach(item => {
        item.isChose = this.isShopChoseAll;
      });
      this.cartListChange();
    },
    //验证是否已经选中所有商品
    isChoseAll_Fut() {
      for (var i = 0; i < this.cartList.length; i++) {
        if (this.cartList[i].isChose == false) {
          this.isShopChoseAll = false;
          break;
        } else {
          this.isShopChoseAll = true;
        }
      }
    },
    //购物车选中总价
    shopCarChosedPriceAll() {
      var priceAll = 0;
      for (let i in this.cartList) {
        if (this.cartList[i].isChose == true) {
          priceAll +=
            Number(this.cartList[i].number) * Number(this.cartList[i].price);
        }
      }
      this.cartChosedAllPrice = priceAll;
    },
    //购物车选中数量
    cartListChosedLength() {
      var num = 0;
      this.cartList.forEach(item => {
        if (item.isChose == true) {
          num++;
        }
      });
      this.cartChosedAllLeng = num;
    },
    //统一数据变化
    cartListChange() {
      this.shopCarChosedPriceAll();
      this.cartListChosedLength();
      this.$forceUpdate();
    },
    //获取购物车数据
    getShopCartList() {
      var cartList = storage.get("WYC_cartList", true);
      if (cartList) {
        this.cartList = cartList;
        this.cartList.forEach(item => {
          item.isChose = false;
        });
        console.log(this.cartList);
      } else {
        this.cartList = [];
      }
    },
    //移除购物车
    removerShopCart(i) {
      var cartList = this.cartList;
      cartList.splice(i, 1);
      this.cartListChange();
      if (this.cartList.length != 0) {
        console.log(2);
        storage.set("WYC_cartList", this.cartList, true);
        window.location.reload();
      } else {
        console.log(1);
        storage.remove("WYC_cartList");
        window.location.reload();
      }
    },
    //删除选中物品
    removeAll() {
      var cartList = this.cartList;
      this.cartList = cartList.filter(item => {
        return item.isChose == false;
      });
      this.isShopChoseAll = false;
      this.cartListChange();
      if (this.cartList.length != 0) {
        console.log(2);
        storage.set("WYC_cartList", this.cartList, true);
        window.location.reload();
      } else {
        console.log(1);
        storage.remove("WYC_cartList");
        window.location.reload();
      }
    },
    //去结算
    toSettle() {
      if (this.cartList.length != 0) {
        var shop = [];
        this.cartList.forEach(item => {
          if (item.isChose) {
            shop.push(item);
          }
        });

        var settlementInfo = {};
        var goods_params = [],
          goods_info = [];

        this.cartList.forEach(item => {
          if (item.isChose) {
            goods_params.push({
              id: item.goods_params[0].id,
              number: item.number,
              skuid: item.skuid
            });
            goods_info.push({
              title: item.title,
              skuTitle: item.skuTitle,
              pic: item.img_path,
              price: item.price,
              number: item.number,
              skuid: item.skuid
            });
          }
        });
        settlementInfo.goods_params = goods_params;
        settlementInfo.goods_info = goods_info;
        settlementInfo.buy_type = 2;
        storage.set("settlementInfo", settlementInfo, true);
        this.routerTo("settlement");
        console.log(shop);
      }
    },
    //跳转到继续购物
    toBuy() {
      this.routerTo("brandSeries");
    },
    //二级路由跳转
    routerTo(value) {
      this.$router.push({ name: value });
    }
  },
  components: {}
};
</script>

<style scoped>
.listBox {
  width: 897px;
  border: 1px solid #333333;
  padding-top: 20px;
}
.on {
  background: #000000;
}
.listBox > div {
  margin-bottom: 20px;
  height: 200px;
}
.listChoseIcon {
  width: 32px;
  height: 32px;
  border: 1px solid #666666;
  border-radius: 2px;
  margin: 0 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listChoseIcon img {
  width: 28px;
}
.info {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  padding: 0 10px;
  line-height: 22px;
  width: 170px;
}
.info div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.priceOne {
  width: 110px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.priceAll {
  width: 110px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.moveBt {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
}
.countNum {
  width: 120px;
}
.countNum_box {
  height: 20px;
  border: 1px solid #999999;
}
.operBox {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.reduce {
  border-right: 1px solid #999999;
}

.reduce img {
  width: 14px;
}

.add {
  border-left: 1px solid #999999;
}

.add img {
  width: 14px;
  height: 14px;
}
.countNum_box .num {
  padding: 0 7px;
  height: 20px;
  line-height: 20px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.listAll {
  height: 30px;
  margin: 20px 0;
}

.listAll_leftText1 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  margin-right: 19px;
  cursor: pointer;
}

.listAll_leftText2 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  cursor: pointer;
}

.shopChosed {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}

.shopPriceAll {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.ship {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}

.listAll .but {
  width: 144px;
  height: 42px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 42px;
  text-align: center;
  cursor: pointer;
}

.listAll .settlement {
  background: #000000;
  margin-left: 10px;
}

.listAll .shopping {
  background: #666666;
}
</style>
